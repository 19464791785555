<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="app-ecommerce-details">
          <div class="row invoice-add">
            <div class="col-12">
              <div class="card" v-if="category">
                <div class="card-body">
                  <h4>Категория {{ category.name }} (#{{ category.id }})</h4>
                  <div class="row">
                    <div class="col-12 mb-2 mb-md-0">
                      <ul class="product-features list-unstyled">
                        <li>
                          <feather-icon icon="LinkIcon"/>
                          Полный путь категории:&nbsp;
                          <router-link :to="{ name: 'category-list', params: { id: category.parent_id } }">
                            {{ category.full_parent_path }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr/>
                  <b-nav tabs>
                    <b-nav-item :to="{ params: {tab: 'options'} }" :active="tab === 'options'">
                      Характеристики товаров
                    </b-nav-item>
                    <b-nav-item :to="{ params: {tab: 'specOptions'} }" :active="tab === 'specOptions'">
                      Характеристики спецификаций
                    </b-nav-item>
                    <b-nav-item :to="{ params: {tab: 'rozetkaOptions'} }" :active="tab === 'rozetkaOptions'">
                      Характеристики товаров для Rozetka
                    </b-nav-item>
                  </b-nav>
                  <div class="tab-content">
                    <div :class="['tab-pane', { 'active': tab === 'options' }]">
                      <div class="row mb-1">
                        <div class="col-12 col-md-6">
                          <v-select
                            id="allOptions"
                            v-model="form.selectedOption"
                            :clearable="false"
                            :options="allOptions"
                            label="name"
                          >
                            <template #option="{ name, description, product_option_group }">
                              <span>
                                {{ name }}{{
                                  description && ` - ${description}`
                                }} {{ product_option_group && `(${product_option_group.name})` }}
                              </span>
                            </template>
                          </v-select>
                        </div>
                        <div class="col-12 col-md-2 pt-1">
                          <b-form-checkbox
                            v-model="form.required"
                            :checked="form.required"
                          >Обязательный</b-form-checkbox>
                        </div>
                        <div class="col-12 col-md-4">
                          <button @click="addOption" class="btn btn-primary btn-block">Добавить Характеристику</button>
                        </div>
                      </div>
                      <div class="position-relative table-responsive">
                        <table class="table b-table">
                          <thead>
                          <!---->
                          <tr>
                            <th>#</th>
                            <th>Название</th>
                            <th>Обязательный</th>
                            <th>Тип</th>
                            <th>Группа</th>
                            <th>Действия</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="option in options" :key="option.id" class="font-small-3">
                            <td>{{ option.dictionary.id }}</td>
                            <td>{{ option.dictionary.name }}</td>
                            <td>{{ option.required ? 'Да' : 'Нет' }}</td>
                            <td>{{ option.dictionary.input_type }}</td>
                            <td>
                              {{
                                option.dictionary.product_option_group
                                    ? option.dictionary.product_option_group.name
                                    : '-'
                              }}
                            </td>
                            <td>
                              <button
                                v-if="$can('manage', 'categories')"
                                @click="removeOption(option.id)"
                                class="btn btn-danger btn-sm"
                              >
                                Удалить
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div :class="['tab-pane', { 'active': tab === 'specOptions' }]">
                      <div class="row mb-1">
                        <div class="col-12 col-md-6">
                          <v-select
                              id="allOptions"
                              v-model="form.selectedOption"
                              :clearable="false"
                              :options="allSpecOptions"
                              label="name"
                          >
                            <template #option="{ name, description, product_option_group }">
                              <span>
                                {{ name }}{{
                                  description && ` - ${description}`
                                }} {{ product_option_group && `(${product_option_group.name})` }}
                              </span>
                            </template>
                          </v-select>
                        </div>
                        <div class="col-12 col-md-2 pt-1">
                          <b-form-checkbox
                              v-model="form.required"
                              :checked="form.required"
                          >Обязательный</b-form-checkbox>
                        </div>
                        <div class="col-12 col-md-4">
                          <button @click="addSpecOption" class="btn btn-primary btn-block">Добавить</button>
                        </div>
                      </div>
                      <div class="position-relative table-responsive">
                        <table class="table b-table">
                          <thead>
                          <!---->
                          <tr>
                            <th>#</th>
                            <th>Название</th>
                            <th>Обязательный</th>
                            <th>Тип</th>
                            <th>Группа</th>
                            <th>Действия</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="option in specOptions" :key="option.id" class="font-small-3">
                            <td>{{ option.dictionary.id }}</td>
                            <td>{{ option.dictionary.name }}</td>
                            <td>{{ option.required ? 'Да' : 'Нет' }}</td>
                            <td>{{ option.dictionary.input_type }}</td>
                            <td>
                              {{
                                option.dictionary.product_option_group
                                    ? option.dictionary.product_option_group.name
                                    : '-'
                              }}
                            </td>
                            <td>
                              <button
                                  v-if="$can('manage', 'categories')"
                                  @click="removeOption(option.id)"
                                  class="btn btn-danger btn-sm"
                              >
                                Удалить
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div :class="['tab-pane', { 'active': tab === 'rozetkaOptions' }]">
                      <div class="row mb-1">
                        <div class="col-12 col-md-6">
                          <v-select
                              id="allOptions"
                              v-model="form.selectedOption"
                              :clearable="false"
                              :options="allOptions"
                              label="name"
                          >
                            <template #option="{ name, description, product_option_group }">
                              <span>
                                {{ name }}{{
                                  description && ` - ${description}`
                                }} {{ product_option_group && `(${product_option_group.name})` }}
                              </span>
                            </template>
                          </v-select>
                        </div>
                        <div class="col-12 col-md-4">
                          <button @click="addRozetkaOption" class="btn btn-primary btn-block">
                            Добавить Характеристику
                          </button>
                        </div>
                      </div>
                      <div class="position-relative table-responsive">
                        <table class="table b-table">
                          <thead>
                          <!---->
                          <tr>
                            <th>#</th>
                            <th>Название</th>
                            <th>Тип</th>
                            <th>Группа</th>
                            <th>Действия</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="option in rozetkaOptions" :key="option.id" class="font-small-3">
                            <td>{{ option.dictionary.id }}</td>
                            <td>{{ option.dictionary.name }}</td>
                            <td>{{ option.dictionary.input_type }}</td>
                            <td>
                              {{
                                option.dictionary.product_option_group
                                    ? option.dictionary.product_option_group.name
                                    : '-'
                              }}
                            </td>
                            <td>
                              <button
                                  v-if="$can('manage', 'categories')"
                                  @click="removeExternalOption(option.id)"
                                  class="btn btn-danger btn-sm"
                              >
                                Удалить
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { BNav, BNavItem, BFormCheckbox } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'CategoryDetails',
  props: ['tab'],
  components: {
    BFormCheckbox, BNav, BNavItem, vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      breadcrumbs: [],
      category: null,
      form: {
        selectedOption: null,
        required: true,
      },
      selectedSize: null,
      sizeCurrentPage: 1,
      sizesSearch: null,
      brandCurrentPage: 1,
      options: [],
      specOptions: [],
      rozetkaOptions: [],
      allOptions: [],
      allSpecOptions: [],
    };
  },
  async mounted() {
    await this.getCategory();
    await this.getAllOptions();
    await this.getAllSpecOptions();
    await this.getOptions();
    await this.getRozetkaOptions();
    await this.getSpecOptions();
  },
  methods: {
    async getCategory() {
      this.category = (await this.$api.categories.get(this.$route.params.id)).data;
    },
    async getOptions() {
      this.options = (await this.$api.categories.options(this.$route.params.id)).data;
    },
    async getRozetkaOptions() {
      this.rozetkaOptions = (await this.$api.categories.externalOptions(this.$route.params.id, 3)).data;
    },
    async getSpecOptions() {
      this.specOptions = (await this.$api.categories.specDictionaries(this.$route.params.id)).data;
    },
    async getAllOptions() {
      this.allOptions = (await this.$api.options.list()).data;
    },
    async getAllSpecOptions() {
      this.allSpecOptions = (await this.$api.specOptions.list()).data;
    },
    async addOption() {
      await this.$api.categories.addOption(this.$route.params.id, {
        product_option_id: this.form.selectedOption.id,
        required: this.form.required,
      });
      this.form.selectedOption = null;
      await this.getOptions();
    },
    async addRozetkaOption() {
      await this.$api.categories.addExternalOption(this.$route.params.id, 3, {
        product_option_id: this.form.selectedOption.id,
        required: this.form.required,
      });
      this.form.selectedOption = null;
      await this.getRozetkaOptions();
    },
    async addSpecOption() {
      await this.$api.categories.addSpecOption(this.$route.params.id, {
        specification_option_id: this.form.selectedOption.id,
        required: this.form.required,
      });
      this.form.selectedOption = null;
      await this.getSpecOptions();
    },
    async removeOption(optionId) {
      this.$bvModal.msgBoxConfirm(
        'Вы уверены, что хотите удалить характеристику? Все значении ее будут удалены из товаров категории',
        {
          title: 'Подтверждение удаления',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.$api.categoryDictionary.delete(optionId);
            this.$bvToast.toast('Характеристика успешно удалена', {
              title: 'Успешно',
              variant: 'success',
              solid: true,
            });

            if (this.tab === 'options') {
              await this.getOptions();
            } else {
              await this.getSpecOptions();
            }
          }
        });
    },
    async removeExternalOption(optionId) {
      this.$bvModal.msgBoxConfirm(
        'Вы уверены, что хотите удалить характеристику?',
        {
          title: 'Подтверждение удаления',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        },
      )
        .then(async (value) => {
          if (value) {
            await this.$api.categoryExternalDictionary.delete(optionId);
            this.$bvToast.toast('Характеристика успешно удалена', {
              title: 'Успешно',
              variant: 'success',
              solid: true,
            });

            await this.getRozetkaOptions();
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.product-img {
  width: auto;
}

.img-block {
  max-width: 200px !important;
  text-align: center;
}
</style>
