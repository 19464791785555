var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content content ecommerce-application",class:[{'show-overlay': _vm.$store.state.app.shallShowOverlay}]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"app-ecommerce-details"},[_c('div',{staticClass:"row invoice-add"},[_c('div',{staticClass:"col-12"},[(_vm.category)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',[_vm._v("Категория "+_vm._s(_vm.category.name)+" (#"+_vm._s(_vm.category.id)+")")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2 mb-md-0"},[_c('ul',{staticClass:"product-features list-unstyled"},[_c('li',[_c('feather-icon',{attrs:{"icon":"LinkIcon"}}),_vm._v(" Полный путь категории:  "),_c('router-link',{attrs:{"to":{ name: 'category-list', params: { id: _vm.category.parent_id } }}},[_vm._v(" "+_vm._s(_vm.category.full_parent_path)+" ")])],1)])])]),_c('hr'),_c('b-nav',{attrs:{"tabs":""}},[_c('b-nav-item',{attrs:{"to":{ params: {tab: 'options'} },"active":_vm.tab === 'options'}},[_vm._v(" Характеристики товаров ")]),_c('b-nav-item',{attrs:{"to":{ params: {tab: 'specOptions'} },"active":_vm.tab === 'specOptions'}},[_vm._v(" Характеристики спецификаций ")]),_c('b-nav-item',{attrs:{"to":{ params: {tab: 'rozetkaOptions'} },"active":_vm.tab === 'rozetkaOptions'}},[_vm._v(" Характеристики товаров для Rozetka ")])],1),_c('div',{staticClass:"tab-content"},[_c('div',{class:['tab-pane', { 'active': _vm.tab === 'options' }]},[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('v-select',{attrs:{"id":"allOptions","clearable":false,"options":_vm.allOptions,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var description = ref.description;
var product_option_group = ref.product_option_group;
return [_c('span',[_vm._v(" "+_vm._s(name)+_vm._s(description && (" - " + description))+" "+_vm._s(product_option_group && ("(" + (product_option_group.name) + ")"))+" ")])]}}],null,false,1246694397),model:{value:(_vm.form.selectedOption),callback:function ($$v) {_vm.$set(_vm.form, "selectedOption", $$v)},expression:"form.selectedOption"}})],1),_c('div',{staticClass:"col-12 col-md-2 pt-1"},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.required},model:{value:(_vm.form.required),callback:function ($$v) {_vm.$set(_vm.form, "required", $$v)},expression:"form.required"}},[_vm._v("Обязательный")])],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.addOption}},[_vm._v("Добавить Характеристику")])])]),_c('div',{staticClass:"position-relative table-responsive"},[_c('table',{staticClass:"table b-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.options),function(option){return _c('tr',{key:option.id,staticClass:"font-small-3"},[_c('td',[_vm._v(_vm._s(option.dictionary.id))]),_c('td',[_vm._v(_vm._s(option.dictionary.name))]),_c('td',[_vm._v(_vm._s(option.required ? 'Да' : 'Нет'))]),_c('td',[_vm._v(_vm._s(option.dictionary.input_type))]),_c('td',[_vm._v(" "+_vm._s(option.dictionary.product_option_group ? option.dictionary.product_option_group.name : '-')+" ")]),_c('td',[(_vm.$can('manage', 'categories'))?_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.removeOption(option.id)}}},[_vm._v(" Удалить ")]):_vm._e()])])}),0)])])]),_c('div',{class:['tab-pane', { 'active': _vm.tab === 'specOptions' }]},[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('v-select',{attrs:{"id":"allOptions","clearable":false,"options":_vm.allSpecOptions,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var description = ref.description;
var product_option_group = ref.product_option_group;
return [_c('span',[_vm._v(" "+_vm._s(name)+_vm._s(description && (" - " + description))+" "+_vm._s(product_option_group && ("(" + (product_option_group.name) + ")"))+" ")])]}}],null,false,1246694397),model:{value:(_vm.form.selectedOption),callback:function ($$v) {_vm.$set(_vm.form, "selectedOption", $$v)},expression:"form.selectedOption"}})],1),_c('div',{staticClass:"col-12 col-md-2 pt-1"},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.required},model:{value:(_vm.form.required),callback:function ($$v) {_vm.$set(_vm.form, "required", $$v)},expression:"form.required"}},[_vm._v("Обязательный")])],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.addSpecOption}},[_vm._v("Добавить")])])]),_c('div',{staticClass:"position-relative table-responsive"},[_c('table',{staticClass:"table b-table"},[_vm._m(1),_c('tbody',_vm._l((_vm.specOptions),function(option){return _c('tr',{key:option.id,staticClass:"font-small-3"},[_c('td',[_vm._v(_vm._s(option.dictionary.id))]),_c('td',[_vm._v(_vm._s(option.dictionary.name))]),_c('td',[_vm._v(_vm._s(option.required ? 'Да' : 'Нет'))]),_c('td',[_vm._v(_vm._s(option.dictionary.input_type))]),_c('td',[_vm._v(" "+_vm._s(option.dictionary.product_option_group ? option.dictionary.product_option_group.name : '-')+" ")]),_c('td',[(_vm.$can('manage', 'categories'))?_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.removeOption(option.id)}}},[_vm._v(" Удалить ")]):_vm._e()])])}),0)])])]),_c('div',{class:['tab-pane', { 'active': _vm.tab === 'rozetkaOptions' }]},[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('v-select',{attrs:{"id":"allOptions","clearable":false,"options":_vm.allOptions,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var description = ref.description;
var product_option_group = ref.product_option_group;
return [_c('span',[_vm._v(" "+_vm._s(name)+_vm._s(description && (" - " + description))+" "+_vm._s(product_option_group && ("(" + (product_option_group.name) + ")"))+" ")])]}}],null,false,1246694397),model:{value:(_vm.form.selectedOption),callback:function ($$v) {_vm.$set(_vm.form, "selectedOption", $$v)},expression:"form.selectedOption"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.addRozetkaOption}},[_vm._v(" Добавить Характеристику ")])])]),_c('div',{staticClass:"position-relative table-responsive"},[_c('table',{staticClass:"table b-table"},[_vm._m(2),_c('tbody',_vm._l((_vm.rozetkaOptions),function(option){return _c('tr',{key:option.id,staticClass:"font-small-3"},[_c('td',[_vm._v(_vm._s(option.dictionary.id))]),_c('td',[_vm._v(_vm._s(option.dictionary.name))]),_c('td',[_vm._v(_vm._s(option.dictionary.input_type))]),_c('td',[_vm._v(" "+_vm._s(option.dictionary.product_option_group ? option.dictionary.product_option_group.name : '-')+" ")]),_c('td',[(_vm.$can('manage', 'categories'))?_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.removeExternalOption(option.id)}}},[_vm._v(" Удалить ")]):_vm._e()])])}),0)])])])])],1)]):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Название")]),_c('th',[_vm._v("Обязательный")]),_c('th',[_vm._v("Тип")]),_c('th',[_vm._v("Группа")]),_c('th',[_vm._v("Действия")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Название")]),_c('th',[_vm._v("Обязательный")]),_c('th',[_vm._v("Тип")]),_c('th',[_vm._v("Группа")]),_c('th',[_vm._v("Действия")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Название")]),_c('th',[_vm._v("Тип")]),_c('th',[_vm._v("Группа")]),_c('th',[_vm._v("Действия")])])])}]

export { render, staticRenderFns }